<template>
  <v-app-bar
    app
    ref="navbar"
    class="appbar white"
    :flat="$vuetify.breakpoint.smAndDown"
    clipped-left
    v-scroll="onScroll"
    v-resize="onResize"
    :height="calculateNavbardHeight"
  >
    <!-- top menu -->
    <!-- <div class="navigation-selector-container px-4">
      <ul class="d-flex font-weight-bold text-center primary--text">
        <li>
          <router-link
            class="h-100 navigation-selector underline py-2"
            :class="site == 'site' ? 'selected' : ''"
            :to="{ name: 'HomeSite' }"
            >Tigros.it</router-link
          >
        </li>
        <li>
          <router-link
            class="h-100 navigation-selector underline py-1 d-flex align-center justify-center"
            :class="site == 'ecommerce' ? 'selected' : ''"
            :to="{ name: 'Home' }"
          >
            <v-icon class="mr-2">$cart</v-icon>
            Spesa Online</router-link
          >
        </li>
      </ul>
    </div> -->

    <!-- navbar -->
    <v-row no-gutters :align="'center'" class="pt-2 pb-0">
      <!-- menu col -->
      <v-col
        cols="4"
        sm="4"
        md="1"
        lg="1"
        class="menu-btn order-0 d-flex justify-start align-center column pl-4"
      >
        <!-- @click.prevent.stop="drawerLeftSite = !drawerLeftSite" -->
        <v-btn
          v-if="site"
          :ripple="false"
          aria-label="Apri menu categorie"
          small
          depressed
          class="desktop-menu-btn drawer-btn"
          @click.prevent.stop="drawerLeft = !drawerLeft"
        >
          <div class="d-flex align-center px-4">
            <v-icon large color="primary">$hamburgerMenu</v-icon>
          </div>
        </v-btn>
        <v-btn
          v-else
          aria-label="Apri menu istituzionale"
          small
          depressed
          class="desktop-menu-btn drawer-btn primary rounded"
          @click.prevent.stop="drawerLeft = !drawerLeft"
        >
          <div class="d-flex align-center px-4">
            <v-icon color="white">
              $menuEcommerce
            </v-icon>
          </div>
        </v-btn>
      </v-col>
      <!-- logo col -->
      <v-col cols="4" sm="4" md="3" lg="2" class="order-1 column">
        <router-link
          :to="site == 'site' ? { name: 'HomeSite' } : { name: 'Home' }"
          class="d-flex align-center justify-center"
        >
          <!-- logo desktop -->
          <img
            class="px-0 logo-desktop d-none d-md-flex"
            :src="'/logo/wal_flagship_logo.jpg'"
            alt="Logo"
            :style="{ height: '75px' }"
          />
          <!-- logo mobile -->
          <img
            class="px-0 logo-mobile mx-auto d-flex d-md-none"
            :src="'/logo/wal_flagship_logo.jpg'"
            alt="Logo Tigros"
            :style="{ height: '40px' }"
          />
        </router-link>
      </v-col>
      <!-- search col -->
      <v-col
        cols="12"
        md="5"
        lg="5"
        class="order-4 order-md-2 d-flex px-md-4 column mt-1 mt-md-0 search-input-col px-4"
      >
        <div class="flex-grow-1 flex-shrink-0 py-1 py-md-0">
          <SearchInput class="pr-auto pr-md-13 pr-lg-auto" />
        </div>
      </v-col>
      <!-- buttons col -->
      <v-col
        cols="4"
        md="3"
        lg="4"
        class="order-2 order-md-3 d-flex justify-end align-center column pr-4"
      >
        <v-layout v-if="!isAuthenticated" align-center justify-end>
          <v-menu
            v-model="visibleLogin"
            ref="loginMenuRef"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-none d-lg-flex align-center reserved-area rounded px-3 py-1"
                :class="isAuthenticated ? 'w-100' : 'w-30'"
                v-bind="attrs"
                v-on="on"
              >
                <!-- <img
                  class="mr-3"
                  height="36px"
                  :src="
                    site == 'site'
                      ? '/logo/logo-small.png'
                      : '/img_layout/navbar/icona-carrello.png'
                  "
                  alt="Logo"
                /> -->

                <span>
                  <b> {{ site == "site" ? "Profile" : "Log in" }} </b><br />
                  Log in
                </span>
                <v-spacer></v-spacer>
                <!-- class="drawer-btn" -->
                <v-btn
                  aria-label="Apri carrello"
                  color="primary"
                  depressed
                  medium
                >
                  <v-icon color="white">$expand</v-icon>
                </v-btn>
              </div>
            </template>
            <Login @submit="closeLogin" />
          </v-menu>
          <div class="d-flex d-lg-none">
            <v-btn
              aria-label="Vai al profilo"
              @click.stop="handleLogin()"
              outlined
              small
              height="48"
              class="mr-2"
            >
              <v-icon color="primary">$profile</v-icon>
              <span
                class="d-none d-sm-inline pl-0 font-weight-regular ml-2"
                v-html="$t('navbar.signup')"
              ></span>
            </v-btn>
            <v-btn
              aria-label="Apri il carrello"
              icon
              tile
              class="rounded"
              @click.stop="handleLogin()"
            >
              <v-icon color="primary">$cart</v-icon>
            </v-btn>
          </div>
        </v-layout>
        <v-layout v-else justify-end align-center>
          <v-btn
            aria-label="Cambia indirizzo di consegna"
            class="d-none d-md-inline-flex rounded mr-2"
            @click.stop="openAddressSelector"
            outlined
            small
            height="48"
            width="48"
          >
            <v-icon color="primary">$location</v-icon>
          </v-btn>
          <v-btn
            aria-label="Cambia fascia oraria di consegna"
            class="mr-2 d-none d-md-inline-flex rounded"
            @click.stop="openTimeslotSelector"
            outlined
            small
            height="48"
            width="48"
          >
            <v-icon color="primary">$editcalendar</v-icon>
          </v-btn>
          <!-- <v-btn
            aria-label="Vai alle liste dei preferiti"
            class="mr-2 d-none d-sm-inline-flex rounded"
            :to="{ name: 'Lists' }"
            outlined
            small
            height="48"
            width="48"
          >
            <v-icon color="primary">$heartfull</v-icon>
          </v-btn> -->
          <div>
            <!-- <v-menu bottom offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  aria-label="Vai al profilo"
                  outlined
                  v-bind="attrs"
                  v-on.stop="on"
                  small
                  class="drawer-btn mr-2"
                >
                  <v-icon tile color="primary">$profile</v-icon>
                </v-btn>
              </template>
              <UserMenu :user="cart.user" />
            </v-menu> -->
            <v-btn
              aria-label="Vai al profilo"
              outlined
              small
              class="drawer-btn mr-2"
              :to="{ name: 'Dashboard' }"
            >
              <v-icon tile color="primary">$profile</v-icon>
            </v-btn>
          </div>
          <div
            class="d-flex align-center cart-total-logged rounded-sm"
            :class="{ disabled: !editableCart }"
          >
            <div
              class="cart-total d-none d-sm-flex"
              role="button"
              @click.stop="openCart()"
            >
              <span
                class="text-caption default--text px-4"
                style="line-height:1"
                v-html="
                  `<b class='primary--text'> ${$n(
                    cart.totalPrice,
                    'currency'
                  )} </b>`
                "
              ></span>
            </div>
            <transition name="bounce" mode="out-in">
              <v-badge
                :content="totalItems"
                :value="totalItems > 0"
                :key="totalItems"
                transition="bounce"
                color="secondary shopping-cart-icon"
                overlap
                offset-x="26"
                offset-y="22"
                class="primary--text font-weight-bold"
              >
                <v-btn
                  aria-label="Apri carrello"
                  medium
                  tile
                  icon
                  @click.stop="openCart()"
                  class="no-border"
                >
                  <v-icon color="primary">$cart</v-icon>
                </v-btn>
              </v-badge>
            </transition>
          </div>
        </v-layout>
      </v-col>
    </v-row>

    <v-progress-linear
      arial-label="Caricamento in corso"
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>
<style scoped lang="scss">
.reserved-area {
  border: 1px solid var(--v-primary-base);
}
::v-deep .shopping-cart-icon {
  color: var(--v-primary-base) !important;
}
.cart-total-logged {
  border: 1px solid var(--v-grey-lighten2);
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .v-btn {
      background-color: var(--v-grey-lighten3);
    }
  }
}

::v-deep .v-toolbar__content {
  display: block;
  padding: 0px;
  transition: height 0.2s ease;
  -webkit-transition: height 0.2s ease;
  .v-btn--icon {
    border: 1px solid var(--v-grey-lighten2);
    &.no-border {
      border: none;
    }
  }
}

.navigation-selector-container {
  font-size: 18px;
  position: relative;
  //border-bottom: 1px solid var(--v-grey-lighten2);
  ul {
    li {
      width: 200px;
      text-align: center;
      padding-right: 0px;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        width: 50%;
      }
      a {
        white-space: nowrap;
        width: 100%;
        transition: color 0.2s ease-in-out;
        position: relative;
        display: inline-block;
        text-decoration: none;
        .v-icon {
          color: var(--v-primary-base);
        }
        &.selected {
          background-color: var(--v-primary-base);
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          color: $white;
          .v-icon {
            color: $white;
          }
        }
        /*  &.underline {
          &:hover {
            &:after {
              width: 100%;
              left: 0;
            }
          }
          &:after {
            bottom: 0;
            content: "";
            display: block;
            height: 5px;
            left: 50%;
            position: absolute;
            background: #ffdd02;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
          }
        }
        &.selected:after {
          width: 100%;
          left: 0;
        }*/
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .search-input-col {
    background-color: var(--v-grey-lighten3);
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
}
.disabled {
  pointer-events: none;
  opacity: 0.26;
}
.username {
  font-size: 12px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    display: none;
  }
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.v-main {
  background-color: $background-color-white;
  padding-top: calc(90px + env(safe-area-inset-top)) !important;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-top: calc(143px + env(safe-area-inset-top)) !important;
  }
}
.v-app-bar--fixed {
  background-color: $secondary !important;
  position: fixed;
  top: 0;
  z-index: 11 !important;
  width: 100%;
  height: auto !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    position: fixed;
    top: 0;
    z-index: 11 !important;
    height: $statusbarheightxs;
    width: 100%;
  }
}

.drawer-btn {
  height: 48px !important;
  width: 48px !important;
  &.desktop-menu-btn {
    background-color: transparent;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    &.desktop-menu-btn {
      width: 68px !important;
    }
  }
}
</style>
<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
//import UserMenu from "@/components/navigation/UserMenu.vue";
import Login from "@/components/Login.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";

import get from "lodash/get";
import { mapActions, mapState } from "vuex";

export default {
  name: "Navbar",
  mixins: [login, cartInfo],
  components: {
    SearchInput,
    //UserMenu,
    Login
  },
  props: {
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      navbarHeightExpanded: 90,
      navbarHeightSmall: 90,
      navbarHeightMobileExpanded: 174,
      navbarHeightMobileSmall: 130,
      calculateNavbardHeight: 0,
      visibleLogin: false
    };
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      // updatedDrawerLeftSite: "app/updatedDrawerLeftSite",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    closeLogin() {
      this.$refs.loginMenuRef.isActive = false;
    },
    onScroll() {
      if (window.scrollY > 55 && !document.body.classList.contains("small")) {
        this.calculateNavbardHeight = this.$vuetify.breakpoint.smAndDown
          ? this.navbarHeightMobileSmall
          : this.navbarHeightSmall;
        document.body.classList.add("small");
      } else if (
        window.scrollY < 55 &&
        document.body.classList.contains("small")
      ) {
        this.calculateNavbardHeight = this.$vuetify.breakpoint.smAndDown
          ? this.navbarHeightMobileExpanded
          : this.navbarHeightExpanded;
        document.body.classList.remove("small");
      }
    },
    onResize() {
      let smAndDown = this.$vuetify.breakpoint.smAndDown;

      if (smAndDown && !document.body.classList.contains("sm-and-down")) {
        this.calculateNavbardHeight = document.body.classList.contains("small")
          ? this.navbarHeightMobileSmall
          : this.navbarHeightMobileExpanded;
        document.body.classList.add("sm-and-down");
      } else if (
        !smAndDown &&
        document.body.classList.contains("sm-and-down")
      ) {
        this.calculateNavbardHeight = document.body.classList.contains("small")
          ? this.navbarHeightSmall
          : this.navbarHeightExpanded;
        document.body.classList.remove("sm-and-down");
      }
    },
    goBack() {
      this.$router.go(-1);
    },

    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    // drawerLeftSite: {
    //   get() {
    //     return this.storeDrawerLeftSite;
    //   },
    //   set(value) {
    //     this.updatedDrawerLeftSite(value);
    //   }
    // },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    },
    logoDesktop() {
      return get(
        this.applicationConfig,
        "metaData.template_model.LOGO_DESKTOP"
      );
    },
    logoMobile() {
      return get(this.applicationConfig, "metaData.template_model.LOGO_MOBILE");
    }
  },
  mounted() {
    // this.onScroll();
    this.onResize();
    this.calculateNavbardHeight = this.$vuetify.breakpoint.smAndDown
      ? this.navbarHeightMobileExpanded
      : this.navbarHeightExpanded;
  },
  watch: {
    visibleLogin() {
      if (this.visibleLogin) {
        this.$recaptchaInstance?.showBadge();
      } else {
        this.$recaptchaInstance?.hideBadge();
      }
    }
  }
};
</script>
